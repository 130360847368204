
import "./footer.css";
import Grid from "@mui/material/Grid";

const Footer = () => {
 


  return (
    <>
      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <img src="../images/logo-light.png" alt="Logo" />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={1}
              >
                {/* Additional content can go here */}
              </Grid>
            </div>
          </div>

          
        </div>
      </footer>
      <div className="legal">
        <span>Made in Berlin with ❤️</span>
      </div>
    </>
  );
};

export default Footer;
