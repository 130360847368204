export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "advertise",
    path: "/advertise",
  },
  {
    text: "verification",
    path: "/verification",
  }
]



export const footer = [

]
