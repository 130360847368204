/* eslint-disable no-undef */
import React, { useState } from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/advertise.png";
import "./advertise.css";

const Advertise = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [moveIn, setMoveIn] = useState("");
  const [moveOut, setMoveOut] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [rent, setRent] = useState("");
  const [deposit, setDeposit] = useState("");
  const [city, setCity] = useState("");
  const [details, setDetails] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [washrooms, setWashrooms] = useState("");
  const [area, setArea] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [amenities, setAmenities] = useState({
    kettle: false,
    dishwasher: false,
    coffeeMachine: false,
    washingMachine: false,
    toaster: false,
    wifi: false,
    tv: false,
    hairdryer: false,
    closet: false,
    microwave: false,
    bathtub: false,
    desk: false,
  });
  const [apartment, setApartment] = useState({
    anmeldung: false,
    childAllowed: false,
    petsAllowed: false,
    smokingAllowed: false,
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleCheckboxChange = (e, setStateFunc) => {
    const { name, checked } = e.target;
    setStateFunc((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleImageSelection = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    // Validate form here, and set errors if necessary

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setIsLoading(true);
     // Format move-in and move-out dates
  const moveInFormatted = new Date(moveIn).toISOString().split("T")[0]; // YYYY-MM-DD
  const moveOutFormatted = new Date(moveOut).toISOString().split("T")[0]; // YYYY-MM-DD




    // Prepare a comma-separated string of the selected amenities
    const selectedAmenities = Object.keys(amenities)
      .filter((amenity) => amenities[amenity])
      .map((amenity) =>
        amenity.charAt(0).toUpperCase() + amenity.slice(1).replace(/([A-Z])/g, " $1").trim()
      )
      .join(", ");

    // Create FormData object
    const formData = new FormData();
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("rent", parseFloat(rent));
    formData.append("deposit", parseFloat(deposit));
    formData.append("details", details);
    formData.append("bedrooms", bedrooms);
    formData.append("washrooms", washrooms);
    formData.append("area", area);
    formData.append("amenities", selectedAmenities);
    formData.append("smoking", apartment.smokingAllowed);
    formData.append("child", apartment.childAllowed);
    formData.append("pet", apartment.petsAllowed);
    formData.append("anmeldung", apartment.anmeldung);
    formData.append("approved", false);
    formData.append("status", "CREATED");
    formData.append("name", name); // Assuming name is not provided; adjust if necessary
    formData.append("category", ""); // Assuming category is not provided; adjust if necessary
    formData.append("city", city); // Assuming city is not provided; adjust if necessary
    formData.append("price", rent);
    formData.append("bedroom", bedrooms);
    formData.append("restroom", washrooms);
    formData.append("description", details);
    formData.append("type", "Apartment"); // Assuming type is fixed; adjust if necessary
    formData.append("moveIn", moveInFormatted);
    formData.append("moveOut", moveOutFormatted);

    // Append images to FormData
    selectedImages.forEach((file, index) => {
      formData.append(`photos`, file);
    });

    // Log the FormData object for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

 // Send the request
 fetch("https://berlinlet-afx0jwo8.b4a.run/api/advertise/", {
  method: "POST",
  body: formData,
})
  .then((response) => response.json())
  .then((data) => {
    console.log("SUCCESS!", data);
    setMessage("Your request is successful, apartment will be listed soon!!");
    setTimeout(() => {
      setMessage(""); // Clear message after 10 seconds
    }, 10000);
  })
  .catch((error) => {
    console.error("FAILED...", error);
    setMessage("Failed to submit details. Please try again.");
    setTimeout(() => {
      setMessage(""); // Clear message after 10 seconds
    }, 10000);
  });


    // Reset form fields
    setMobileNumber("");
    setEmail("");
    setMoveIn("");
    setMoveOut("");
    setAddress("");
    setRent("");
    setDeposit(""); // Reset deposit field
    setDetails("");
    setBedrooms("");
    setWashrooms("");
    setArea("");
    setAmenities({
      kettle: false,
      dishwasher: false,
      coffeeMachine: false,
      washingMachine: false,
      toaster: false,
      wifi: false,
      tv: false,
      hairdryer: false,
      closet: false,
      microwave: false,
      bathtub: false,
      desk: false,
    });
    setApartment({
      anmeldung: false,
      childAllowed: false,
      petsAllowed: false,
      smokingAllowed: false,
    });
    setSelectedImages([]);
    setErrors({});
  };

  return (
    <>
      <section className="about">
        <Back name="Advertise with us" title="How it works?" cover={img} />
        <div className="container flex mtop">
          <div className="left row">
            <Heading title="Advertise Your Property" />
            <p>
              Please fill out the form with your property details and contact
              information. We'll get back to you once we receive your information!
            </p>
          </div>
          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="mobile">
              <strong>Mobile Number:</strong>
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={mobileNumber}
              onChange={(e) => handleInputChange(e, setMobileNumber)}
              required
            />
            {errors.mobileNumber && (
              <p className="error-message">{errors.mobileNumber}</p>
            )}

            <label htmlFor="email">
              <strong>Email:</strong>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => handleInputChange(e, setEmail)}
              required
            />
            {errors.email && (
              <p className="error-message">{errors.email}</p>
            )}

            <label htmlFor="address">
              <strong>Address:</strong>
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={(e) => handleInputChange(e, setAddress)}
              required
            />
                <label htmlFor="city">
              <strong>City:</strong>
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={city}
              onChange={(e) => handleInputChange(e, setCity)}
              required
            />

<label htmlFor="moveIn">
              <strong>Move In:</strong>
            </label>
            <input
              type="date"
              id="moveIn"
              name="moveIn"
              value={moveIn}
              onChange={(e) => handleInputChange(e, setMoveIn)}
              required
            />

            <label htmlFor="moveOut">
              <strong>Move Out:</strong>
            </label>
            <input
              type="date"
              id="moveOut"
              name="moveOut"
              value={moveOut}
              onChange={(e) => handleInputChange(e, setMoveOut)}
              required
            />


            <label htmlFor="rent">
              <strong>Rent:</strong>
            </label>
            <input
              type="text"
              id="rent"
              name="rent"
              value={rent}
              onChange={(e) => handleInputChange(e, setRent)}
              required
            />

            <label htmlFor="deposit">
              <strong>Deposit:</strong>
            </label>
            <input
              type="text"
              id="deposit"
              name="deposit"
              value={deposit}
              onChange={(e) => handleInputChange(e, setDeposit)}
              required
            />

        <label htmlFor="address">
              <strong>Appartment Title:</strong>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => handleInputChange(e, setName)}
              required
            />

            <label htmlFor="details">
              <strong>Details:</strong>
              <small> (How many rooms? Entire apartment? How many persons living in?)</small>
            </label>
            <textarea
              id="details"
              name="details"
              value={details}
              onChange={(e) => handleInputChange(e, setDetails)}
              required
            />

            <label htmlFor="bedrooms">
              <strong>Number of Bedrooms:</strong>
            </label>
            <input
              type="text"
              id="bedrooms"
              name="bedrooms"
              value={bedrooms}
              onChange={(e) => handleInputChange(e, setBedrooms)}
              required
            />

            <label htmlFor="washrooms">
              <strong>Number of Washrooms:</strong>
            </label>
            <input
              type="text"
              id="washrooms"
              name="washrooms"
              value={washrooms}
              onChange={(e) => handleInputChange(e, setWashrooms)}
              required
            />

          

            <label htmlFor="apartment">
              <strong>Apartment:</strong>
            </label>
            {Object.keys(apartment).map((aptFeature) => (
              <div className="checkbox-wrapper-46" key={aptFeature}>
                <input
                  type="checkbox"
                  id={`cbx-${aptFeature}`}
                  className="inp-cbx"
                  name={aptFeature}
                  checked={apartment[aptFeature]}
                  onChange={(e) => handleCheckboxChange(e, setApartment)}
                />
                <label
                  htmlFor={`cbx-${aptFeature}`}
                  className="cbx"
                >
                  <span>
                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                      <polyline points="1.5 6 4.5 9 10.5 1" />
                    </svg>
                  </span>
                  <span>{aptFeature.charAt(0).toUpperCase() + aptFeature.slice(1)}</span>
                </label>
              </div>
            ))}

            <label htmlFor="amenities">
              <strong>Amenities:</strong>
            </label>
            {Object.keys(amenities).map((amenity) => (
              <div className="checkbox-wrapper-46" key={amenity}>
                <input
                  type="checkbox"
                  id={`cbx-${amenity}`}
                  className="inp-cbx"
                  name={amenity}
                  checked={amenities[amenity]}
                  onChange={(e) => handleCheckboxChange(e, setAmenities)}
                />
                <label htmlFor={`cbx-${amenity}`} className="cbx">
                  <span>
                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                      <polyline points="1.5 6 4.5 9 10.5 1" />
                    </svg>
                  </span>
                  <span>{amenity.charAt(0).toUpperCase() + amenity.slice(1).replace(/([A-Z])/g, " $1").trim()}</span>
                </label>
              </div>
            ))}

<label htmlFor="photos">
<strong>Upload Photos:</strong> A maximum of 3 photos is accepted.

            </label>
            <input
              type="file"
              id="photos"
              name="photos"
              accept="image/*"
              multiple
              onChange={handleImageSelection}
            />
            {selectedImages.length > 0 && (
              <p>{selectedImages.length} photo(s) selected</p>
            )}

            <button type="submit" className="btn1" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </button>
            {message && <p className="success-message">{message}</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default Advertise;