import React, { useState } from "react";
import "./header.css";
import { nav } from "../../data/Data";
import { Link } from "react-router-dom";

const Header = () => {
  const [navList, setNavList] = useState(false);

  const handleLinkClick = () => {
    setNavList(false); // Close the navigation menu
  };

  return (
    <header>
      <div className='logo'>
        <Link to="/" onClick={handleLinkClick}>
          <img src='./images/logo.png' alt='Logo' />
        </Link>
      </div>
      <div className='nav'>
        <ul className={navList ? "small" : "flex"}>
          {nav.map((list, index) => (
            <li key={index}>
              <Link to={list.path} onClick={handleLinkClick}>{list.text}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className='toggle' onClick={() => setNavList(!navList)}>
        {navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
      </div>
    </header>
  );
};

export default Header;
