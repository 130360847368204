// Home.js
import React, { useState } from "react";
import Hero from "./hero/Hero";
import Recent from "./recent/Recent";
import Grid from "@mui/material/Grid";
import { Route, Switch } from "react-router-dom";
import Advertise from "../advertise/Advertise";

const Home = () => {
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  return (
    <Switch>
      <Route path="/advertise" component={Advertise} />
      <Route path="/">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Hero
            selectedLocation={selectedLocation}
            onLocationChange={handleLocationChange}
          />
          <Recent selectedLocation={selectedLocation} />
        </Grid>
      </Route>
    </Switch>
  );
};

export default Home;
