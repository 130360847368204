import React, { useState, useEffect } from 'react';
import Header from '../common/header/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../home/Home';
import Footer from '../common/footer/Footer';
import Blog from '../blog/Blog';
import Advertise from '../advertise/Advertise';
import PropertyCardDetail from '../property/PropertyCardDetail';
import VerificationCard from '../verification/VerificationCard';
import About from '../about/About';
import ScrollToTop from '../scroll/ScrollToTop';
import Signup from '../account/Signup';
import Loading from '../loading/Loading';  // Importing the Loading component

const Pages = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);  // Simulate a loading time
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Router>
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/property/:id' component={PropertyCardDetail} />
          <Route exact path='/verification' component={VerificationCard} />
          <Route exact path='/about' component={About} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/advertise' component={Advertise} />
          <Route exact path='/signup' component={Signup} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default Pages;
