import React from "react";
import Heading from "../../common/Heading";
import "./hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <Heading
            title="Find Your Next Home"
          />
          <p className="verification-note">
            🚫 Avoid fake home ads 💪 We got your back {''}
            <Link to="/verification">Learn More</Link>
          </p>

          {/* Removed the location dropdown form */}

          <div className="advertising-card">
            <h2>Advertise Your Home 🏡</h2>
            <Link to="/advertise" className="btn1">
              Advertise Now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
