import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./recent.css";

const RecentCard = ({ selectedLocation }) => {
  const [properties, setProperties] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("");
  const history = useHistory();

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://berlinlet-afx0jwo8.b4a.run/api/advertise/");
        setProperties(response.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    fetchData();
  }, []);

  console.log(properties);

  const filteredProperties = selectedLocation
    ? properties.filter((property) => property.city === selectedLocation)
    : properties;

  const sortedProperties = [...filteredProperties].sort((a, b) => {
    if (sortCriteria === "price") {
      return a.price - b.price;
    } else if (sortCriteria === "moveIn") {
      const moveInA = a.moveIn.toLowerCase();
      const moveInB = b.moveIn.toLowerCase();

      if (moveInA === "immediately" && moveInB !== "immediately") {
        return -1;
      }
      if (moveInA !== "immediately" && moveInB === "immediately") {
        return 1;
      }

      return new Date(a.moveIn) - new Date(b.moveIn);
    }
    return 0;
  });

  const handleCardClick = (id) => {
    history.push(`/property/${id}`);
  };

  return (
    <div className="recent">
      <div className="sort-dropdown">
        <label htmlFor="sortCriteria">Sort by: </label>
        <select
          id="sortCriteria"
          value={sortCriteria}
          onChange={(e) => setSortCriteria(e.target.value)}
        >
          <option value="">Select</option>
          <option value="price">Price</option>
          <option value="moveIn">Date</option>
        </select>
      </div>
      <div className="content grid3 mtop">
        {sortedProperties.map((property) => (
          <div key={property.id} onClick={() => handleCardClick(property.id)}>
            <PropertyCardPreview 
              property={property} 
              mobileNumber={property.mobileNumber} 
              email={property.email} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const PropertyCardPreview = ({ property, mobileNumber, email }) => {
  const {
    photos,
    name,
    address,
    moveIn,
    moveOut,
    anmeldung,
    deposit,
    price,
    type,
    bedrooms,
    amenities,
  } = property;

  // Generate dynamic URLs using mobileNumber and email
  const whatsappUrl = `https://wa.me/${mobileNumber}?text=Hey%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(
    address
  )}%2C%20Could%20you%20provide%20more%20details%20%3F`;
  const emailUrl = `mailto:${email}?subject=Interest%20in%20apartment%20at%20${encodeURIComponent(
    address
  )}&body=Hey%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(
    address
  )}%2C%20Could%20you%20provide%20more%20details%20%3F`;
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;

  const handleContactIconClick = (e) => {
    e.stopPropagation();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not specified";
    
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    
    return date.toLocaleDateString('en-GB', options).toUpperCase();  // Example: 16 JAN 2025
  };

  return (
    <div className="box shadow">
      <div className="img">
        <img src={photos[0]} alt={name} />
      </div>
      <div className="text">
        <div className="category flex">
          <div className="verified">
            <span>Verified</span>
            <i className="fa fa-check-circle"></i>
          </div>
        </div>
        <h4>{name || "No Name Available"}</h4>
        <p>
          <i className="fa fa-location-dot"></i> {address || "No Location Available"}
        </p>
        <p>
          {anmeldung ? (
            <span style={{ color: "green" }}>With Anmeldung</span>
          ) : (
            <span style={{ color: "orange" }}>Without Anmeldung</span>
          )}{" "}
          <br />
          <strong>Bedrooms:</strong> {bedrooms || "Not specified"} <br />
          <strong>Move In:</strong> {formatDate(moveIn) || "Not specified"} <br />
          <strong>Move Out:</strong> {formatDate(moveOut) || "Not specified"} <br />
          <strong>Deposit:</strong> €{deposit || "N/A"}
          <br />
          <strong>Amenities:</strong> {amenities || "No Amenities Available"}
          <br />
        </p>
        <div className="contact-icons">
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000"
              alt="WhatsApp Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
          <a
            href={mapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="maps-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=y9LJ0mNaH6XG&format=png&color=000000"
              alt="Google Maps Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
          <a
            href={emailUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="email-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=124193&format=png&color=000000"
              alt="Email Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
        </div>
      </div>
      <div className="button flex">
        <div>
          <button className="btn2">€{price}</button>
        </div>
        <span>{type}</span>
      </div>
    </div>
  );
};

export default RecentCard;
