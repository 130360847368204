import React from "react";
import Heading from "../../common/Heading";
import "./recent.css";
import RecentCard from "./RecentCard";

const Recent = ({ selectedLocation }) => {
  return (
    <>
      <section className="recent padding">
        <div className="container">
          <Heading
            title="Property Listed"
            subtitle="🏠Explore our personally verified apartments to find your perfect match!"
          />
          {/* Render RecentCard directly without routing */}
          <RecentCard selectedLocation={selectedLocation} />
        </div>
      </section>
    </>
  );
};

export default Recent;
