import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='About Us'  />
            <p>At RentUp, we're a group of passionate young individuals with a vision to transform the housing industry to its maximum potential and efficiency. Our journey began with a simple idea - to redefine the way people interact with the concept of housing. 🏡</p>
            <p>We believe that innovation and creativity are the keys to unlocking the true potential of the housing sector. ✨</p>
            <p>For any inquiries or questions, feel free to contact us at <a href="mailto:berlinlet@gmail.com">berlinlet@gmail.com</a>. 📧</p>
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
